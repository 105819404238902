<template>
  <div class="ep-empty-data">
    <template v-if="icon">
      <img class="ep-empty-data-icon" :src="icon" :alt="iconDesc" />
    </template>
    <div class="ep-empty-data-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "EpEmptyData",
  props: {
    icon: {
      type: String,
      default: require("@/assets/icons/focus-card-icon.png")
    },
    iconDesc: {
      type: String,
      default: "카드아이콘"
    },
    text: {
      type: String,
      default: "해당하는 카드가 없습니다."
    }
  }
};
</script>
<!--
TestData
icon: {
type: String,
default: require("@/assets/icons/focus-card-icon.png")
},
text: {
type: String,
default: '해당하는 카드가 없습니다.'
}
-->

<style lang="scss">
.ep-empty-data {
  text-align: center;
  padding: rem(85px) 0 rem(105px);

  &-icon {
    width: 66px;
    height: 64px;
    margin-bottom: 28px;
  }

  &-text {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.2px;
    color: $color-grey-4;
    opacity: 0.8;
  }
}
</style>
