<template>
  <div class="ep-benefit-card-list">
    <div v-if="isItem">
      <component
        :is="cardComponent"
        v-bind="card"
        v-for="(card, index) in list"
        :key="index + Date.now()"
        @click-card="(e) => onClickCard(e, index)"
        @display-card="onDisplayCard"
        :is-event-open="isEventOpen"
        :event-comp-list="eventCompList"
      />
    </div>
    <ep-empty-data :text="emptyMessage" :icon="emptyImage" v-else />
    <!--
    사업팀 요청에 따라 해당 기능 미사용하도록 함
     <ep-list-more-button v-if="limit !== 0" :is-more="isMore" :switch-text-list="switchTextList" @more="more" @reset="reset" />
     -->
  </div>
</template>

<script>
import EpBenefitCard from "@/components/benefit/EpBenefitCard";
import EpBenefitCardLoan from "@/components/benefit/EpBenefitCardLoan";
import EpBenefitCardInsurance from "@/components/benefit/EpBenefitCardInsurance";
import EpEmptyData from "@/components/common/EpEmptyData";
import EpListMoreButton from "@/components/benefit/EpListMoreButton";

export default {
  name: "EpBenefitCardList",
  components: {
    EpBenefitCard,
    EpBenefitCardLoan,
    EpBenefitCardInsurance,
    EpEmptyData,
    EpListMoreButton
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 5 // 0 이면 더보기 없음
    },
    cardType: {
      type: String,
      default: "" // loan: 추천 대출 상품 카드
    },
    emptyMessage: {
      type: String
    },
    emptyImage: {
      type: String
    },
    switchTextList: {
      type: Array
    },
    isEventOpen: {
      type: Boolean
    },
    eventCompList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isMore: false,
      totalPageCount: 0,
      totalCount: 0,
      pageCount: 1,
      list: [] //현재 보여지는 리스트,
    };
  },
  computed: {
    cardComponent() {
      switch (this.cardType) {
        case "loan":
          return EpBenefitCardLoan;
        case "insurance":
          return EpBenefitCardInsurance;
        default:
          return EpBenefitCard;
      }
    },
    isItem() {
      return this.data.length > 0;
    }
  },
  watch: {
    data() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.setPage();
      this.list = this.getCurrentList();
      this.isMore = this.checkMore();
    },
    setPage() {
      this.pageCount = 1;
      this.totalCount = this.data.length;

      if (this.limit <= 0) {
        this.totalPageCount = 1;
      } else {
        this.totalPageCount = Math.ceil(this.totalCount / this.limit);
      }
    },
    getCurrentList() {
      if (this.limit <= 0) {
        return this.data;
      } else {
        return this.data.slice(0, this.limit * this.pageCount);
      }
    },
    checkMore() {
      return this.totalCount > this.list.length;
    },
    more() {
      if (this.pageCount < this.totalPageCount) {
        this.pageCount++;
        this.list = this.getCurrentList();
        this.isMore = this.checkMore();
      }
    },
    onClickCard(clickedCardEvent, index) {
      this.$emit("click-card", clickedCardEvent, index);
    },
    onDisplayCard(displayedCardElement) {
      this.$emit("display-card", displayedCardElement);
    }
  },
  created() {
    this.reset();
  }
};
</script>

<!--
TestData
data: {
type: Array,
default: () => [
            {
                "gpMappSeq": 2200,
                "adsDtlSeq": 1135,
                "adsSeq": 1935,
                "payYn": "N",
                "compNm": "웰컴저축은행",
                "loanName": "웰컴 중금리대출",
                "itSeq": null,
                "destinationType": "P",
                "destinationUrl": "",
                "imgPath": "https://devcontents.ebmp.kr/202201/2022012713350724.jpg",
                "promotionSeq": "",
                "licenseNo1": "웰컴저축은행 준법감시인 심의필 제1699호(21.12.23 ~ 22.12.22)",
                "licenseNo2": "",
                "interestMaxRate": "16.0",
                "interestMinRate": "5.9",
                "creditLineText": null,
                "support": "0",
                "advtTypeCd": "20",
                "advtText": "웰컴 TEST",
                "advtColor": "#0A65EE"
            },
            {
                "gpMappSeq": 2201,
                "adsDtlSeq": 1135,
                "adsSeq": 1935,
                "payYn": "N",
                "compNm": "웰컴저축은행",
                "loanName": "웰컴 중금리대출",
                "itSeq": null,
                "destinationType": "P",
                "destinationUrl": "",
                "imgPath": "https://devcontents.ebmp.kr/202201/2022012713350724.jpg",
                "promotionSeq": "",
                "licenseNo1": "웰컴저축은행 준법감시인 심의필 제1699호(21.12.23 ~ 22.12.22)",
                "licenseNo2": "",
                "interestMaxRate": "16.0",
                "interestMinRate": "5.9",
                "creditLineText": null,
                "support": "0",
                "advtTypeCd": "20",
                "advtText": "웰컴 TEST",
                "advtColor": "#0A65EE"
            },
            {
                "gpMappSeq": 2202,
                "adsDtlSeq": 1136,
                "adsSeq": 1936,
                "payYn": "N",
                "compNm": "NH농협캐피탈",
                "loanName": "NH농협캐피탈 물론 다이렉트론",
                "itSeq": null,
                "destinationType": "C",
                "destinationUrl": "https://m.nhcapital.co.kr/landing/mo/m_nhcapital_landing20.nh?INTFLDCHN=27&INTFLDTYP=02&utm_source=pass&utm_medium=dn&utm_campaign=%ED%95%98%EB%8B%A8%EB%B0%B0%EB%84%88",
                "imgPath": "",
                "promotionSeq": "",
                "licenseNo1": "준법심의필-2112-17(2021.12.29.)",
                "licenseNo2": "",
                "interestMaxRate": "19.9",
                "interestMinRate": "4.9",
                "creditLineText": null,
                "support": "0",
                "advtTypeCd": "20",
                "advtText": "NH농협캐피탈 대출",
                "advtColor": "#9E23FF"
            }
        ]
-->

<style lang="scss">
.ep-benefit-card-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
