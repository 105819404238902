<template>
  <div class="ep-button-list" :class="{ scroll: isScroll }">
    <div class="ep-button-list-inner" :class="{ scroll: isScroll }">
      <ep-button v-for="(item, index) in data" :key="index" :code="item.code" :code-nm="item.codeNm" :is-icon="item.isIcon" :is-clicked="item.isClicked" @click.native="onClickButton(index)" />
    </div>
  </div>
</template>

<script>
import EpButton from "@/components/benefit/EpButton";

export default {
  name: "EpButtonList",
  components: {
    EpButton
  },
  props: {
    isScroll: {
      type: Boolean,
      default: true
    },
    isMultipleSelect: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      clickedButtonSet: new Set()
    };
  },
  methods: {
    onClickButton(clickedIndex) {
      if (this.isMultipleSelect) {
        this.updateMultipleSelect(clickedIndex);
      } else {
        this.update(clickedIndex);
      }
      this.$emit("click-categories", [...this.clickedButtonSet.keys()]);
    },
    toggle(clickedIndex) {
      const { isClicked, code } = this.data[clickedIndex];

      this.data[clickedIndex].isClicked = !isClicked;

      if (this.data[clickedIndex].isClicked) {
        this.clickedButtonSet.add(code);
      } else {
        this.clickedButtonSet.delete(code);
      }
    },
    select(clickedIndex) {
      this.data.forEach((element, index) => {
        const isClickedIndex = clickedIndex === index;

        if (isClickedIndex) {
          element.isClicked = true;
          this.clickedButtonSet.add(element.code);
        } else {
          if (element.isToggle) return;
          element.isClicked = false;
          this.clickedButtonSet.delete(element.code);
        }
      });
    },
    update(clickedIndex) {
      const { isToggle } = this.data[clickedIndex];

      // 클릭된 요소 추가
      this.data.forEach((element) => {
        if (element.isClicked) {
          this.clickedButtonSet.add(element.code);
        }
      });

      if (isToggle) {
        this.toggle(clickedIndex);
      } else {
        this.select(clickedIndex);
      }
    },
    updateMultipleSelect(clickedIndex) {
      this.data[clickedIndex].isClicked = !this.data[clickedIndex].isClicked;

      this.data.forEach((element) => {
        if (element.isClicked) {
          this.clickedButtonSet.add(element.code);
        } else {
          this.clickedButtonSet.delete(element.code);
        }
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.ep-button-list {
  margin: -3px -3px;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    display: none;
    height: 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &.scroll {
    overflow-x: scroll;
    //-webkit-overflow-scrolling: touch;
    //-webkit-transform: translateZ(0);
  }

  &-inner.scroll {
    width: max-content;
  }
}
</style>
