<template>
  <div class="benefit-list-wrap">
    <ul class="benefit-list" :class="{ 'benefit-list-loan': isLoan }">
      <li class="benefit-list-item" :key="index" v-for="(item, index) in data">
        <div
          :data-it-seq="item.itSeq"
          :data-promotion-seq="item.promotionSeq"
          :data-gp-mapp-seq="item.gpMappSeq"
          :data-ads-seq="item.adsSeq"
          :data-pay-yn="item.payYn"
          :data-comp-nm="item.compNm"
          class="benefit-list-item-link"
          @click.prevent="onClick"
          role="button"
        >
          <div class="benefit-list-item-img" v-if="item.logoImg">
            <img :src="item.logoImg" :alt="`${item.compNm} 로고 이미지`" />
          </div>
          <dl class="benefit-list-item-text">
            <dt>{{ item.compNm }}</dt>
            <!--            <dd>{{ item.supportText }}</dd>-->
          </dl>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BenefitList",
  components: {},
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    cardType: {
      type: String,
      default: "" // or loan
    }
  },
  computed: {
    isLoan() {
      return this.cardType === "loan";
    }
  },
  methods: {
    onClick(clickedCardEvent) {
      this.$emit("click-link", clickedCardEvent);
    }
  }
};
</script>

<style lang="scss" scoped>
.benefit-list {
  display: inline-flex;
  align-items: stretch;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin: -(2.5px/2);
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: rem(11.5px);
  letter-spacing: -0.5px;

  $g-benefit-list: &;

  &-wrap {
    overflow: hidden;
    margin: 0 rem(24px);
  }

  &-item {
    flex: 0 1 auto;
    width: 50%;

    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2.5px;
      padding: 10px 0;
      white-space: nowrap;
      text-decoration: none;
      border-radius: 10px;
      background: #f6f7f8;
    }

    &-img {
      flex: 0 0 35px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 500em;
      border: 1px solid $color-grey-1;
      vertical-align: top;

      img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }

    &-text {
      display: inline-block;
      margin-top: 4px;
      color: $color-grey-5;
      text-align: left;
      font-size: rem(13px);
      line-height: (18/12);
      letter-spacing: -0.5px;

      dd {
        font-weight: 700;
        font-size: rem(14px);
      }
    }
  }

  /* 대출 리스트 */
  &-loan {
    &.benefit-list {
      margin: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px 8px;
    }

    #{$g-benefit-list}-item {
      flex: none;
      width: initial;
    }

    #{$g-benefit-list}-item-link {
      margin: 0;
    }

    #{$g-benefit-list}-item-text {
      dd {
        font-size: rem(12px);
      }

      @include respond-to("small-ios") {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
