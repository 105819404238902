<template>
  <div v-if="imgPath" class="ep-image">
    <div class="ep-image-outer">
      <div class="ep-image-inner">
        <img class="ep-image-rotate" :src="imgPath" :alt="imgDesc" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "EpCardImage",
  props: {
    imgPath: {
      type: String,
      default: ""
    },
    imgDesc: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
$card-width: 436;
$card-height: 274;

.ep-image {
  &-outer {
    position: relative;
    padding-bottom: $card-width/$card-height * 100%;
  }

  &-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-rotate {
    display: block;
    transform-origin: top left;
    height: $card-height/$card-width * 100%;
    transform: rotate(90deg) translate(0, -100%);
  }
}
</style>
