<template>
  <div class="ep-benefit-card-container">
    <div class="ep-benefit-card-container-title-container">
      <span v-if="title" class="ep-benefit-card-container-title">{{ title }}</span>
      <ep-benefit-switch-button v-if="categoryListOverflow" :is-extend.sync="isExtend" @click-extend="onClickExtend" />
    </div>
    <div class="ep-benefit-card-container-button-list">
      <ep-button-list ref="categoryList" :is-scroll="!isExtend" :is-multiple-select="isMultipleSelect" :data="buttonData" @click-categories="onClickCategories"></ep-button-list>
    </div>
    <ul class="ep-benefit-card-container-order-type-list" v-if="orderTypeList && orderTypeList.length > 0">
      <li v-for="(item, index) in orderTypeList" @click="onClickOrderType" :key="index" :class="{ active: item.isClicked }" :data-index="index" :data-code="item.code">&middot; {{ item.codeNm }}</li>
    </ul>
    <div class="ep-benefit-card-container-card-list">
      <ep-benefit-card-list
        ref="benefitCardList"
        :card-type="cardType"
        :data="cardData"
        :empty-message="emptyMessage"
        :empty-image="emptyImage"
        :limit="cardDataLimit"
        :is-event-open="isEventOpen"
        :event-comp-list="eventCompList"
        @click-card="onClickCard"
        @display-card="onDisplayCard"
      />
    </div>
  </div>
</template>

<script>
import EpBenefitSwitchButton from "@/components/benefit/EpBenefitSwitchButton";
import EpButtonList from "@/components/benefit/EpButtonList";
import EpBenefitCardList from "@/components/benefit/EpBenefitCardList";

export default {
  name: "EpBenefitCardContainer",
  components: {
    EpBenefitSwitchButton,
    EpButtonList,
    EpBenefitCardList
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    cardType: {
      type: String,
      default: ""
    },
    isMultipleSelect: {
      type: Boolean
    },
    buttonData: {
      type: Array,
      default: () => []
    },
    cardDataLimit: {
      type: Number
    },
    cardData: {
      type: Array,
      default: () => []
    },
    orderTypeList: {
      type: Array,
      default: () => []
    },
    emptyMessage: {
      type: String // 기본값은 해당 값이 쓰이는 컴포넌트 내에 정의 한다.
    },
    emptyImage: {
      type: String
    },
    isEventOpen: {
      type: Boolean
    },
    eventCompList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isExtend: false,
      categoryListOverflow: true
    };
  },
  methods: {
    onClickCard(clickedCardEvent, index) {
      this.$emit("click-card", clickedCardEvent, index);
    },
    onClickCategories(clickedCategoryList) {
      this.$emit("click-categories", clickedCategoryList);
    },
    onClickOrderType(event) {
      this.$emit("click-order-type", event);
    },
    onClickExtend(changeState) {
      this.$emit("click-extend", changeState);
    },
    onDisplayCard(displayedCardElement) {
      this.$emit("display-card", displayedCardElement);
    },
    getCategoryListOverflowState() {
      this.categoryListOverflow = this.$refs.categoryList.$el.firstElementChild.getBoundingClientRect().right > this.$refs.categoryList.$el.getBoundingClientRect().right;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCategoryListOverflowState();
    });
  }
};
</script>

<style lang="scss">
.ep-benefit-card-container {
  &-title-container {
    display: flex;
  }

  &-title {
    font-weight: bold;
    font-size: rem(20px);
    line-height: (30/20);
    letter-spacing: -0.2px;
    color: $color-grey-6;
    vertical-align: middle;
  }

  &-button-list {
    //margin-top: rem(14px);
  }

  &-order-type-list {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: $color-grey-4;

    li {
      margin: 0 8px 0 0;
    }

    .active {
      color: #005eea;
    }
  }
}
</style>
