<template>
  <div>
    <button type="button" class="ep-list-more-button" @click.prevent="onClick" role="button">
      <span>{{ isMore ? switchTextList[1] : switchTextList[0] }}</span>
      <i class="ep-list-more-button-icon" :class="{ on: isMore }" :style="{ backgroundImage: `url(${switchIcon})` }" :alt="isMore ? switchTextList[1] : switchTextList[0]"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "EpListMoreButton",
  components: {},
  props: {
    isMore: {
      type: Boolean,
      default: false
    },
    switchIcon: {
      type: String,
      default: require("@/assets/icons/chevron-down-icon--grey.png")
    },
    switchTextList: {
      type: Array,
      default: () => ["카드 간단히 보기", "카드 더보기"]
    }
  },
  data() {
    return {};
  },
  methods: {
    onClick(e) {
      if (this.isMore) {
        this.$emit("more", e);
      } else {
        this.$emit("reset");
      }
    }
  }
};
</script>
<style lang="scss">
.ep-list-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  width: 100%;
  padding: 13px 0;
  background-color: transparent;
  border: none;
  white-space: nowrap;
  outline: none;

  &-icon {
    width: (24px/2);
    height: (16px/2);
    margin: 0 0 0 6px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    transition: all 0.5s;

    &.on {
      transform: rotate(0);
    }
  }

  span {
    display: block;
    @include normal_14_22(-0.006em);
    text-align: right;
    color: #666;
  }
}
</style>
