<template>
  <div
    class="ep-insurance-card"
    :data-destination-type="destinationType"
    :data-destination-url="destinationUrl"
    :data-gp-mapp-seq="gpMappSeq"
    :data-ads-dtl-seq="adsDtlSeq"
    :data-ads-seq="adsSeq"
    :data-pay-yn="payYn"
    :data-insu-seq="insuSeq"
    :data-it-seq="itSeq"
    :data-group-seq="groupSeq"
    :data-comp-nm="compNm"
    :data-insu-nm="insuName"
    @click="onClick"
    role="button"
  >
    <div class="ep-insurance-card-img" :style="{ backgroundImage: `url(${imgPath})` }"></div>
    <section class="ep-insurance-card-info">
      <h1 class="ep-insurance-card-name">{{ insuName }}</h1>
      <div class="ep-insurance-card-inner">
        <p class="ep-insurance-card-desc">
          {{ desc1 }}
        </p>
        <p class="ep-insurance-card-desc ep-insurance-card-support" v-if="desc2">
          <span v-html="desc2"></span>
        </p>
        <p class="ep-insurance-card-license" v-if="licenseNo1">{{ licenseNo1 }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import "intersection-observer";

export default {
  name: "EpInsuranceCard",
  components: {
    // EpImage
  },
  props: {
    groupSeq: {
      type: String,
      default: ""
    },
    insuName: {
      type: String,
      default: ""
    },
    compNm: {
      type: String,
      default: ""
    },
    insuSeq: {
      type: String,
      default: ""
    },
    itSeq: {
      type: String,
      default: ""
    },
    destinationType: {
      type: String,
      default: ""
    },
    destinationUrl: {
      type: String,
      default: ""
    },
    imgPath: {
      type: String,
      default: ""
    },
    desc1: {
      type: String,
      default: ""
    },
    desc2: {
      type: String,
      default: ""
    },
    licenseNo1: {
      type: String,
      default: ""
    },
    gpMappSeq: {
      type: Number,
      default: null
    },
    adsDtlSeq: {
      type: Number,
      default: null
    },
    adsSeq: {
      type: Number,
      default: null
    },
    payYn: {
      type: String,
      default: "N"
    }
  },
  data() {
    return {
      observer: null,
      displayed: false
    };
  },
  methods: {
    onClick(e) {
      this.$emit("click-card", e);
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.displayed) {
            this.displayed = true;
            this.$emit("display-card", this.$el);
          }
        });
      },
      {
        root: null,
        threshold: 0
      }
    );
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
.ep-insurance-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(20px) 0;
  border-bottom: 1px solid $color-grey-1;

  & p {
    margin-bottom: 0;
  }

  &-img {
    width: 50px;
    height: 50px;
    margin-right: 21px;
    border-radius: 500em;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  &-info {
    flex: 1;
    margin: 0;
  }

  &-name {
    @include ellipsis(1);
    margin: 0 0 2px;
    font-size: rem(14px);
    font-weight: 700;
    line-height: (22/16);
    letter-spacing: -0.01em;
    color: #333;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &-desc {
    @include ellipsis(1);
    width: 100%;
    position: relative;
    align-items: start;
    flex: none;
    margin: 3px 0 0;
    font-size: rem(13px);
    line-height: (18/13);
    letter-spacing: -0.06em;
    color: $color-grey-4;
  }

  &-support,
  &-license {
    flex: 100%;
  }

  &-support {
    //font-size: rem(14px);
    font-weight: 700;
    color: #005eea;
    letter-spacing: -0.2px;
    line-height: (21/13);
    margin-top: 0;
  }

  &-license {
    @include ellipsis(2);
    margin: 2px 0 0;
    font-size: 11px;
    line-height: (18/11);
    letter-spacing: -0.5px;
    color: $color-grey-3;
    word-wrap: break-word; /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap; /* current browsers */

    &:first-child {
      margin-top: 2px;
    }
  }
}
</style>
