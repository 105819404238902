<template>
  <button class="ep-card-button" :class="{ clicked: isClicked || isToggle }">
    <!-- this need to load image dynamically, it may not find resources from the caller
<img :src="require(`@/assets/icons/button/${icon}-icon--${clicked ? 'white' : 'grey'}.png`)"/>
-->
    <span class="ep-card-button-icon" v-if="isIcon">
      <img :src="require(`@/assets/icons/button/icon-${code.toLowerCase()}-white.png`)" v-if="isClicked || isToggle" :alt="codeNm + '버튼이 선택됨'" />
      <img :src="require(`@/assets/icons/button/icon-${code.toLowerCase()}-grey.png`)" v-else :alt="codeNm + '버튼이 선택 해제됨'" />
    </span>
    <span class="ep-card-button-text"> {{ codeNm }}</span>
  </button>
</template>

<script>
export default {
  name: "EpCardButton",
  props: {
    codeNm: {
      type: String,
      default: ""
    },
    code: {
      type: String,
      default: ""
    },
    isToggle: {
      type: Boolean,
      default: false
    },
    isClicked: {
      type: Boolean,
      default: false
    },
    isIcon: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.ep-card-button {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  height: rem(42px);
  margin: 3px 3px;
  padding: 0 rem(12px);
  font-weight: bold;
  font-size: rem(14px);
  text-align: center;
  color: #858585;
  border: 1px solid $color-grey-1;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-appearance: none;
  background: transparent;
  transition: all 0.2s;
  line-height: 1;

  &-icon {
    display: inline-block;
    margin-right: 4px;
    img {
      height: 20px;
      width: 20px;
      vertical-align: top;
    }
  }

  &-text {
  }

  &:focus {
    // remove default browser's focus behavior
    outline: none;
  }

  &.clicked {
    background: $color-grey-6;
    border: 1px solid $color-grey-6;
    color: #ffffff;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
