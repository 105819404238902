<template>
  <div
    class="ep-loan-card"
    :data-promotion-seq="promotionSeq"
    :data-it-seq="itSeq"
    :data-destination-type="destinationType"
    :data-destination-url="destinationUrl"
    :data-gp-mapp-seq="gpMappSeq"
    :data-ads-dtl-seq="adsDtlSeq"
    :data-ads-seq="adsSeq"
    :data-pay-yn="payYn"
    :data-comp-nm="compNm"
    :data-loan-nm="loanName"
    @click="onClick"
    role="button"
  >
    <div class="ep-loan-card-img" :style="{ backgroundImage: `url(${imgPath})` }">
      <i class="icon-promotion" v-if="showPromotionIcon(compNm)"></i>
    </div>
    <section class="ep-loan-card-info">
      <h1 class="ep-loan-card-name">{{ loanName }}</h1>
      <div class="ep-loan-card-inner">
        <dl class="ep-loan-card-desc">
          <dt>금리(연)</dt>
          <dd>
            <em>{{ interestMinRate }}</em>
            <em v-if="interestMaxRate"> ~ {{ interestMaxRate }}%</em>
          </dd>
        </dl>

        <dl class="ep-loan-card-desc line-block" v-if="adsSeq === ltvSeq">
          <dt>최대</dt>
          <dd>
            <em>LTV 99%</em>
          </dd>
        </dl>
        <dl class="ep-loan-card-desc" :class="{ 'line-block': creditLineText.length < 11 }" v-else-if="creditLineText">
          <dt>최대</dt>
          <dd>
            <em>{{ creditLineText }}</em>
          </dd>
        </dl>

        <template v-if="!!advtTypeCd">
          <template v-if="advtTypeCd === '10'">
            <dl class="ep-loan-card-desc ep-loan-card-support" v-if="support && support !== '0'">
              <dt>대출이자</dt>
              <dd>
                <em v-html="`${support}% 지원`"></em>
              </dd>
            </dl>
          </template>
          <template v-else-if="advtTypeCd === '20'">
            <dl class="ep-loan-card-desc ep-loan-card-support-text" v-if="advtText">
              <dd>
                <em v-html="advtText" :style="{ color: advtColor }"></em>
              </dd>
            </dl>
          </template>
        </template>
        <div class="ep-loan-card-license" v-if="licenseNo1">{{ licenseNo1 }}</div>
        <div class="ep-loan-card-license" v-if="licenseNo2">{{ licenseNo2 }}</div>
      </div>
    </section>
  </div>
</template>

<script>
import "intersection-observer";

export default {
  name: "EpLoanCard",
  components: {
    // EpImage
  },
  props: {
    loanName: {
      type: String,
      default: ""
    },
    destinationType: {
      type: String,
      default: ""
    },
    destinationUrl: {
      type: String,
      default: ""
    },
    imgPath: {
      type: String,
      default: ""
    },
    promotionSeq: {
      type: String,
      default: ""
    },
    itSeq: {
      type: String,
      default: ""
    },
    licenseNo1: {
      type: String,
      default: ""
    },
    licenseNo2: {
      type: String,
      default: ""
    },
    interestMaxRate: {
      type: String,
      default: ""
    },
    interestMinRate: {
      type: String,
      default: ""
    },
    creditLineText: {
      type: String,
      default: ""
    },
    advtTypeCd: {
      type: String,
      default: ""
    },
    support: {
      type: String,
      default: ""
    },
    advtText: {
      type: String,
      default: ""
    },
    advtColor: {
      type: String,
      default: ""
    },
    gpMappSeq: {
      type: Number,
      default: null
    },
    adsDtlSeq: {
      type: Number,
      default: null
    },
    adsSeq: {
      type: Number,
      default: null
    },
    payYn: {
      type: String,
      default: "N"
    },
    compNm: {
      type: String,
      default: ""
    },
    isEventOpen: {
      type: Boolean
    },
    eventCompList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      observer: null,
      displayed: false
    };
  },
  computed: {
    //TODO 백엔드 작업전까지 담보대출 상품 특정하여 노출할 수 있도록 하는 코드
    ltvSeq() {
      return 1264;
    }
  },
  methods: {
    onClick(e) {
      this.$emit("click-card", e);
    },
    // TODO 이벤트 종료 후 삭제 필요
    showPromotionIcon(compName) {
      return this.eventCompList.includes(compName) && this.isEventOpen;
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.displayed) {
            this.displayed = true;
            this.$emit("display-card", this.$el);
          }
        });
      },
      {
        root: null,
        threshold: 0
      }
    );
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
.ep-loan-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(20px) 0;
  border-bottom: 1px solid $color-grey-1;

  &:last-child {
    border-bottom: none;
  }

  &-img {
    width: 50px;
    height: 50px;
    margin-right: 21px;
    border-radius: 500em;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
  }

  &-info {
    flex: 1;
    margin: 0;
  }

  &-name {
    @include ellipsis(1);
    margin: 0;
    @include bold_16_22(-0.01em);
    color: #333;
  }

  &-inner {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
  }

  .line-block {
    &:before {
      display: inline-block;
      width: 1px;
      height: calc(100% - 6px);
      margin: 0 12px;
      background: $color-grey-1;
      content: "";
    }

    margin-top: 0 !important;

    @include respond-to("x-small") {
      &:before {
        display: none;
      }
      margin-top: initial;
      width: 100%;
    }
  }

  &-desc {
    position: relative;
    display: flex;
    align-items: center;
    flex: none;
    margin: 3px 0 0;
    @include normal_13_21(-0.06em);

    &:first-child {
      margin: 0;
    }

    dt {
      margin-right: 5px;
      color: $color-grey-4;
    }

    dd {
      margin: 0;
      color: $color-grey-5;
      letter-spacing: -0.2px;

      em {
        font-style: normal;
      }
    }
  }

  &-support,
  &-license,
  &-support-text {
    flex: 100%;
  }

  &-support {
    dt,
    dd {
      //font-size: rem(14px);
      color: #005eea;
      @include bold_13_13(-0.5px);
    }
  }

  &-support-text {
    dt,
    dd {
      @include bold_13_13(-0.5px);
    }
  }

  &-license {
    margin: 4px 0 0 0;

    @include normal_11_18();
    color: $color-grey-3;
    word-wrap: break-word; /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap; /* current browsers */

    &:first-child {
      margin-top: 2px;
    }
  }

  .icon-promotion {
    background-image: url("~@/assets/icons/icon-promotion@2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
