<template>
  <span class="ep-benefit-switch-button" @click="onClick">
    <span class="ep-benefit-switch-button-text" role="button">{{ isExtend ? buttonSwitchTextList[0] : buttonSwitchTextList[1] }}</span>
    <span v-if="buttonSwitchIcon" class="ep-benefit-switch-button-icon">
      <img :class="{ all: isExtend }" :src="buttonSwitchIcon" alt="펼치기 아이콘" />
    </span>
  </span>
</template>

<script>
export default {
  name: "EpBenefitSwitchButton",
  props: {
    isExtend: {
      type: Boolean,
      default: false
    },
    buttonSwitchTextList: {
      type: Array,
      default: () => ["간단히 보기", "펼쳐서 보기"]
    },
    buttonSwitchIcon: {
      type: String,
      default: require("@/assets/icons/chevron-down-icon--dark-grey.png")
    }
  },
  methods: {
    onClick() {
      this.$emit("update:isExtend", !this.isExtend);
      this.$emit("click-extend", !this.isExtend); // 업데이트 될 상태를 전달함.
    }
  }
};
</script>

<style lang="scss">
.ep-benefit-switch-button {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  margin-left: auto !important;
  cursor: pointer;

  &-text {
    @include bold_12_20(-0.2px);
    color: rgba(68, 68, 68, 0.6);
  }

  &-icon {
    width: 20px;
    display: flex;
    justify-content: center;
    margin-top: 1px;

    img {
      vertical-align: middle;
      width: 7.5px;
      height: 3.75px;

      transform: rotate(0deg);
      transition-property: transform;
      transition-duration: 0.5s;

      &.all {
        transform: rotate(180deg);
        transition-property: transform;
        transition-duration: 0.5s;
      }
    }
  }
}
</style>
