<template>
  <page-container>
    <div v-if="!isLoadedData">
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <v-skeleton-loader type="card-heading,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"></v-skeleton-loader>
      <v-skeleton-loader type="card-heading, image"></v-skeleton-loader>
    </div>
    <div ref="mainBanner">
      <ep-banner-list
        :data="mainBannerList"
        :options="swiperOptions"
        card-type="loan"
        @click-prev="onClickPrev"
        @click-next="onClickNext"
        @stop-autoplay="onClickAutoplay('off')"
        @start-autoplay="onClickAutoplay('on')"
        @swipe="onSwipe"
        @click-slide="onClickSlide"
        @change-active-index="onChangeActiveIndex"
        v-if="isLoadedData && mainBannerList && mainBannerList.length > 0"
      />
    </div>
    <section class="collect-loan" v-if="isLoadedData">
      <ep-benefit-card-container
        :title="`${title}`"
        :button-data="categoryList"
        :order-type-list="orderTypeList"
        :card-data="filteredBenefitList"
        :card-data-limit="0"
        :empty-image="require('@/assets/icons/icon-empty-loan@2x.png')"
        :is-event-open="isEventOpen"
        :event-comp-list="eventCompList"
        empty-message="해당하는 대출 상품이 없습니다."
        card-type="loan"
        @click-card="onClickCard"
        @click-categories="onClickCategories"
        @click-order-type="updateOrderType"
        @click-extend="onClickExtend"
        @display-card="onDisplayCard"
      />
    </section>
    <section class="card-section" v-if="isLoadedData">
      <h1 class="card-section-title">금융사별 모아보기</h1>
      <benefit-list :data="topSupportList" card-type="loan" @click-link="onClickLink" />
    </section>
    <section class="card-section banner-section" v-if="isLoadedData">
      <img @click="onClickImageBanner" src="~@/assets/images/banner/syrup.png" alt="시럽 배너" aria-describedby="syrup_banner" />
      <div hidden id="syrup_banner">
        <div class="syrup-banner-text-bold">
          원하시는 대출 상품이 없나요?
        </div>
        <div class="syrup-banner-text-bold">
          여기서 더 찾아보세요.
        </div>
      </div>

      <!--      <div class="syrup-banner" @click="onClickImageBanner">-->
      <!--        <div>-->
      <!--          <div class="syrup-banner-text-bold">-->
      <!--            OK캐쉬백 대출 이자 지원 캠페인-->
      <!--          </div>-->
      <!--          <div class="syrup-banner-text">-->
      <!--            놓치지 말고 확인해보세요-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <img class="syrup-banner-img" @click="onClickImageBanner" src="~@/assets/images/banner/syrup-pig.png" alt="시럽 배너 이미지" />-->
      <!--      </div>-->
    </section>
    <section class="card-section customer-info" v-if="isLoadedData">
      <div class="customer-info-message">OK캐쉬백은 고객님의 정보를 안전하게 관리합니다.</div>
      <div class="customer-info-button-wrapper"><button class="customer-info-button" @click="onClickShowAgree">동의 정보 보기</button></div>
    </section>
  </page-container>
</template>

<script>
import PageContainer from "@/components/layout/PageContainer";
import BenefitList from "@/components/BenefitList";

import { uiGetters, userGetters, loanGetters, loanActions, uiActions, userActions } from "@/store";
import { AppCommand } from "@/api/bridge";

import { traceEvent, gaTracker, getTraceQuery } from "@/helpers/tracer";
import { move, openPushWebview } from "@/helpers/move";
import { addSearchQuery, cleanObject, getLocalStorageItem, isEmptyList, isEmptyObject } from "@/helpers";
import { ROUTES_NAME } from "@/const/router";
import EpBannerList from "@/components/mainbanner/EpBannerList";
import EpBenefitCardContainer from "@/components/benefit/EpBenefitCardContainer";
// eslint-disable-next-line
import { CREATIVE_DTL_TYPE, DEFAULT_TITLE, IS_DEV_ENV, OCB_TRACK_ID, SUB_TYPE_DICT, TYPE_DICT, WV_TITLE } from "@/const";

export default {
  name: ROUTES_NAME.LOAN,
  components: {
    PageContainer,
    BenefitList,
    EpBannerList,
    EpBenefitCardContainer
  },
  data() {
    return {
      IS_DEV_ENV: IS_DEV_ENV,
      initialize: false, // to call only once
      isLoadedData: false, // to show skeleton UI

      popupDelayedTrackDataList: [],
      filteredBenefitList: [],
      clickedCategoryList: [],
      orderType: "H", // H : 슬롯순
      orderTypeList: [
        /*{
          isClicked: false,
          code: "S",
          codeNm: "최대지원금"
        },*/
        {
          isClicked: false,
          code: "L",
          codeNm: "최저금리"
        },
        {
          isClicked: false,
          code: "M",
          codeNm: "최대한도"
        }
      ],
      isDisplayedMainBanner: true,
      isEventOpen: false,
      eventCompList: ["우리카드", "웰컴저축은행", "하나캐피탈", "하나저축은행"]
    };
  },
  computed: {
    ...uiGetters(["isGlobalAgreeDialogShow", "isGlobalPushDialogAlreadyOpen", "agreeCookieName", "isGlobalPushDialogShow"]),
    ...userGetters(["osType", "token", "userInfo", "trackCdType", "custNm", "loanMainBannerRollingTime", "isNeedAgree", "isPushList", "trackCd", "isInitialUser"]),
    ...loanGetters(["mainBannerList", "topSupportList", "categoryList", "benefitList"]),

    title() {
      if (this.custNm && this.custNm !== "") {
        return `${this.custNm}님을 위한 대출 상품`;
      } else {
        return "OK캐쉬백 대출 상품";
      }
    },

    categoryName() {
      if (isEmptyList(this.categoryList) || isEmptyList(this.clickedCategoryList)) return "전체";

      const clickedCategory = this.categoryList.filter((element) => element.code === this.clickedCategoryList[0]);
      return clickedCategory[0] ? clickedCategory[0].codeNm : "전체";
    },

    swiperOptions() {
      return {
        simulateTouch: IS_DEV_ENV,
        autoplay: {
          delay: this.loanMainBannerRollingTime,
          disableOnInteraction: false
        }
      };
    }
  },
  watch: {
    async isShow(value) {
      if (!value && this.isInsert) {
        await this.updateBenefitList();
      }
    },
    async orderType(value) {
      if (value) {
        await this.updateBenefitList();
      }
    },
    userInfo: {
      immediate: true,
      async handler(v) {
        // do not use isLoadedData, isLoadedData needs some time to fetch data.
        if (Object.keys(v).length > 0 && !this.initialize) {
          this.initialize = true;

          // EBMP 미가입자인 경우 약관 전체 미동의로 우리쪽에 데이터를 쌓는다 (PUSH 등으로 바로 들어온 경우 대비)
          // 반드시 getData 보다 먼저 와야 한다. 로깅을 적절히 해야하고, 신규 페이지에서 바로 재차 신규 생성 요청이 들어갈 수 있기 때문에
          if (this.isInitialUser) {
            await this.setAgreeInfo({
              agreeInfoForm: {
                trdInfo: false
              }
            });
          }

          // setAgreeInfo 다음에 와야 함.
          await this.getData();

          this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX } });
        }
      }
    },
    isGlobalPushDialogShow(value) {
      if (!value) {
        this.popupDelayedTrackDataList.forEach((data) => {
          this.wrapTraceEvent(data, "I001");
        });
      }
    }
  },
  methods: {
    ...uiActions(["setGlobalAgreeDialog", "setGlobalPushDialog", "setDirectLoanMainFromPush", "setBridgeDialog", "setPromotionDialog"]),
    ...loanActions(["fetchLoanCategory", "fetchLoanTopSupport", "fetchLoanBanner", "fetchLoanBenefitList"]),
    ...userActions(["fetchUser", "setAgreeInfo"]),
    onClickImageBanner() {
      gaTracker({
        category: "대출 메인 > OCB 배너(P356)",
        action: `클릭`,
        label: `${this.trackCdType}`
      });

      this.$bridge.requestToApp(AppCommand.START_BROWSER, {
        url: "https://loan.syrup.co.kr/ocb/intro.html?utm_source=OCB&utm_medium=finbridge&utm_campaign=loan_Web&utm_term=ocb&utm_content=finbridge"
      });

      // https://ebmp.kr/ocb/loan?promotion_seq=356
      // this.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
      //   url: addSearchQuery(`${window.location.origin}/loan/promotion/${IS_DEV_ENV ? "353" : "356"}`, getTraceQuery()),
      //   title: DEFAULT_TITLE
      // });
    },
    getOpenWebviewObj() {
      let { promotion_seq, prod_seq, it_seq, ads_seq } = this.$route.query;
      let check = {};
      const FILTER_TRACK_CD_TYPE = {
        PUSH2078: {
          promotion_seq: "272",
          ads_seq: "2078"
        },
        PUSH2079: {
          promotion_seq: "272",
          ads_seq: "2079"
        },
        PUSH2333: {
          promotion_seq: "320",
          ads_seq: "2333"
        },
        PUSH2334: {
          promotion_seq: "320",
          ads_seq: "2334"
        },
        PUSH2502: {
          promotion_seq: "356",
          ads_seq: "2502"
        },
        PUSH2503: {
          promotion_seq: "356",
          ads_seq: "2503"
        },
        PUSH2542: {
          promotion_seq: "356",
          ads_seq: "2542"
        }
      };
      const FILTERED = FILTER_TRACK_CD_TYPE[this.trackCdType];
      if (FILTERED) {
        check = { promotion_seq: FILTERED.promotion_seq };
        ads_seq = FILTERED.ads_seq;
      } else {
        check = cleanObject({ promotion_seq, prod_seq, it_seq });
      }

      if (isEmptyObject(check)) return null;
      if (Object.keys(check).length >= 2) return console.error("The url has too many seqs");

      return { type: TYPE_DICT.LOAN, subType: SUB_TYPE_DICT[Object.keys(check)[0]], seq: Object.values(check)[0], ads_seq };
    },
    onClickShowAgree() {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "bottom_tap.agreebtn" } });

      gaTracker({
        category: "대출 메인 > 동의정보 보기",
        action: `클릭`,
        label: `${this.trackCdType}`
      });

      this.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
        url: addSearchQuery(`${process.env.VUE_APP_URI}/terms`, getTraceQuery()),
        title: WV_TITLE.TERMS
      });

      // 정보 동의/해제 페이지에서 사용자 정보 변경이 일어나므로, 사용자 정보에 대한 refresh가 필요하다. ex) 사용자 이름
      this.$bridge.requestToApp(AppCommand.REGISTER_ON_RESUME, {
        objectMethodName: "EbmpRegister.resume",
        handler: async () => {
          await this.fetchUser();
        }
      });
    },
    onClickToken() {
      this.$bridge.requestToApp(AppCommand.ADD_TEXT_TO_CLIP_BOARD, { text: this.token });
    },

    onClickSlide(slideElement) {
      const dataset = slideElement.dataset;

      gaTracker({
        category: "대출 메인 > 상단 배너",
        action: `[${dataset.compNm}] 상단 배너 클릭`,
        label: `${this.trackCdType}`
      });

      this.wrapTraceEvent(dataset, "C001");

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "top_tap.banner", common_code: dataset.compNm } });

      //{ promotionSeq, destinationType, destinationUrl, gpMappSeq, adsSeq, payYn }
      move({ ...dataset, type: CREATIVE_DTL_TYPE.LOAN });
    },
    onClickPrev() {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "banner_tap.prev" } });

      gaTracker({
        category: `대출 메인 > 상단 배너`,
        action: `이전 클릭`,
        label: `${this.trackCdType}`
      });
    },
    onClickNext() {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "banner_tap.next" } });

      gaTracker({
        category: `대출 메인 > 상단 배너`,
        action: `다음 클릭`,
        label: `${this.trackCdType}`
      });
    },
    onSwipe() {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "banner_swipe.list" } });

      gaTracker({
        category: `대출 메인 > 상단 배너`,
        action: `스와이프`,
        label: `${this.trackCdType}`
      });
    },
    onClickAutoplay(onOff) {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "banner_tap.auto", common_code: onOff } });

      gaTracker({
        category: `대출 메인 > 상단 배너`,
        action: `[${onOff}] 클릭`,
        label: `${this.trackCdType}`
      });
    },

    onChangeActiveIndex(swiper, activeIndex, realIndex) {
      //if (this.isGlobalPushDialogShow) return;

      if (this.isDisplayedMainBanner) {
        this.wrapTraceEvent(this.mainBannerList[realIndex], "I001"); //메인배너
      }
    },

    onClickLink(clickedLink) {
      const dataset = clickedLink.currentTarget.dataset;

      gaTracker({
        category: "대출 메인 > 대출사별 혜택 모아보기",
        action: `[${dataset.compNm}] 혜택 모아보기 배너 클릭`,
        label: `${this.trackCdType}`
      });

      this.wrapTraceEvent(dataset, "C002");
      //{ promotionSeq, destinationType, destinationUrl, gpMappSeq, adsSeq, payYn }
      move({ ...dataset, type: CREATIVE_DTL_TYPE.LOAN });
    },

    onDisplayCard(displayedCardElement) {
      /*if (this.isGlobalPushDialogShow) {
        this.popupDelayedTrackDataList.push({ ...displayedCardElement.dataset });
        return;
      }*/

      this.wrapTraceEvent(displayedCardElement.dataset, "I001");
    },

    wrapTraceEvent(data, eventType) {
      const { gpMappSeq, adsSeq, payYn } = data;

      traceEvent({
        eventType: eventType,
        gpMappSeq,
        adsSeq,
        payYn
      });
    },

    // 펼쳐서 보기
    onClickExtend(changeState) {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "list_tap.fold", common_code: changeState ? "open" : "close" } });

      gaTracker({
        category: `대출 메인 > 펼쳐서 보기`,
        action: `[${changeState ? "open" : "close"}] 클릭`,
        label: `${this.trackCdType}`
      });
    },

    // 카테고리 선택
    onClickCategories(clickedCategoryList) {
      this.clickedCategoryList = clickedCategoryList;

      gaTracker({
        category: "대출 메인 > 혜택 유형별 대출 상품 카테고리",
        action: `[${this.categoryName}] 클릭`,
        label: `${this.trackCdType}`
      });

      this.updateBenefitList();

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "list_tap.category", category_name: this.categoryName } });
    },

    // 정렬 순서 선택
    updateOrderType(e) {
      const currentIndex = e.currentTarget.dataset.index;
      const orderType = this.orderTypeList[currentIndex];

      // 모두 해제 한다.
      this.orderTypeList.forEach((element) => {
        element.isClicked = false;
      });

      let orderName = "";
      if (this.orderType !== orderType.code) {
        orderType.isClicked = true;
        this.orderType = orderType.code;
        orderName = orderType.codeNm;
      } else {
        // 동일한 것을 눌렀을 경우 해제된 상태로 슬롯 순으로 한다.
        // 디폴트로 특별한 정렬살태가 없는 경우이다.
        this.orderType = "H";
      }

      gaTracker({
        category: "대출 메인 > 정렬 순서",
        action: `[${orderName}] 클릭`,
        label: `${this.trackCdType}`
      });

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.INDEX, action_id: "list_tap.filter", category_name: orderName } });
    },

    onClickCard(clickedCardEvent, index) {
      const dataset = clickedCardEvent.currentTarget.dataset;

      gaTracker({
        category: "대출 메인 > 혜택 유형별 대출 상품",
        action: `[${dataset.loanNm}] 혜택 유형별 배너 클릭`,
        label: `${this.trackCdType}`
      });

      this.wrapTraceEvent(dataset, "C001");
      //{ promotionSeq, destinationType, destinationUrl, gpMappSeq, adsSeq, payYn }
      move({ ...dataset, type: CREATIVE_DTL_TYPE.LOAN });

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, {
        log: { page_id: OCB_TRACK_ID.INDEX, action_id: "list_tap.financialproduct", category_name: this.categoryName, common_code: dataset.loanNm, display_order: index }
      });
    },

    async updateBenefitList() {
      await this.fetchLoanBenefitList({
        categoryCodeList: this.clickedCategoryList,
        orderType: this.orderType,
        price: this.isInsert ? this.price : "",
        point: this.isInsert ? this.point : "",
        creditType: this.isInsert ? this.creditType : ""
      });

      this.filteredBenefitList = this.benefitList;
    },

    async getData() {
      this.isLoadedData = false;

      // 사용자 동의
      const isAgreeCookie = !!getLocalStorageItem(this.agreeCookieName);

      // 사용자 정보에서 동의가 필요하고 쿠키에서 동의한 적이 없는 경우
      if (this.isNeedAgree && !isAgreeCookie) {
        this.setGlobalAgreeDialog({ isShow: true });
      } else {
        this.setGlobalAgreeDialog({ isShow: false });
      }

      // Do not change this function to computed. We change query dynamically in the openPushWebview
      const openWebviewObj = this.getOpenWebviewObj();
      // 하단 팝업 또는 새 페이지
      if (openWebviewObj) {
        openPushWebview(openWebviewObj);
      } else {
        // 여기서 track_cd_type의 목적은 walk in을 체크하고자 하는 것이 아니고, 실제 push 발송시 track_cd_type을 넣은 것이 있는지를 체크하기 위함이다.
        // 따라서 store의 값을 사용하면 안된다.
        const { ads_seq, track_cd_type } = this.$route.query;
        traceEvent({
          eventType: "I007",
          adsSeq: ads_seq
        });

        // push 용 상품리스트가 있고, 새 페이지를 열지 않고 메인으로 진입하는 것이며, 한번 닫은 적이 없는 경우
        // API에서 어차피 데이터(isPushList)를 제공하고 있지 않아서 미사용 기능이나 소스 일치화를 위해 작성되어 있다.
        if (track_cd_type && this.isPushList && !this.isGlobalPushDialogAlreadyOpen) {
          this.setGlobalPushDialog({ isShow: true });
        } else {
          this.setGlobalPushDialog({ isShow: false });
        }
      }

      // 카테고리
      await this.fetchLoanCategory();

      // 상단
      await this.fetchLoanBanner();

      const defaultCategory = this.categoryList.find((item) => item.isClicked);
      this.clickedCategoryList = defaultCategory?.code ? [defaultCategory.code] : [];

      // 중단
      await this.fetchLoanBenefitList({
        categoryCodeList: this.clickedCategoryList,
        price: "",
        point: "",
        creditType: "",
        orderType: this.orderType
      });

      // 하단
      await this.fetchLoanTopSupport();

      this.filteredBenefitList = this.benefitList;
      this.isLoadedData = true;
    },
    handleScroll() {
      this.checkDisplayedMainBanner();
    },
    checkDisplayedMainBanner() {
      const { mainBanner } = this.$refs;

      if (!mainBanner) return;

      const height = mainBanner.offsetHeight;
      const top = mainBanner.getBoundingClientRect().top;

      this.isDisplayedMainBanner = Math.abs(top) <= height;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
.card-section {
  @include section();
  padding-bottom: 20px;
}

.banner-section {
  padding: 0;
  //height: 73px;
  img {
    width: 100%;
    display: block;
  }

  .syrup-banner {
    background-color: #feffd0;
    padding: 10px 31px 9px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      display: flex;
      flex-direction: column;
      font-size: rem(14px);
      line-height: (22/14);
      font-weight: 500;

      &-bold {
        font-size: rem(16px);
        line-height: (22/16);
        font-weight: 700;
      }
    }

    &-img {
      height: 54px;
    }
  }
}

.customer-info {
  padding: 20px 20px;

  &-message {
    color: $color-grey-3;
    @include normal_12_18();
    text-align: center;
  }

  &-button {
    @include normal_12_18();
    text-decoration-line: underline;
    color: $color-grey-5;
  }

  &-button-wrapper {
    margin-top: 10px;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid $color-grey-1;
  }
}

.collect-loan {
  @include section();
  padding-bottom: 0;
  position: relative;
  margin-top: 0;

  .category-list-wrap {
    padding: 10px 0;
    background-color: #fff;
  }

  .benefit-card-list-wrap {
    background-color: #fff;
  }

  ::v-deep {
    .ep-benefit-card-container-title-container {
      @include section-title();
    }
    .ep-benefit-card-container-card-list {
      padding-right: rem(24px);
      padding-left: rem(24px);
    }

    .ep-benefit-card-container-button-list {
      .ep-button-list-inner {
        padding-right: rem(24px);
        padding-left: rem(24px);
        padding-bottom: 10px;
      }
    }

    .ep-benefit-card-container-order-type-list {
      padding: 6px 30px;
      background-color: #f5f6f7;
    }
  }
}
</style>
