<template>
  <div
    class="ep-benefit-card"
    :data-card-seq="cardSeq"
    :data-promotion-seq="promotionSeq"
    :data-it-seq="itSeq"
    :data-destination-type="destinationType"
    :data-destination-url="destinationUrl"
    :data-gp-mapp-seq="gpMappSeq"
    :data-ads-dtl-seq="adsDtlSeq"
    :data-ads-seq="adsSeq"
    :data-pay-yn="payYn"
    :data-comp-nm="compNm"
    :data-card-nm="cardName"
    @click="onClick"
    role="button"
  >
    <ep-image class="ep-benefit-card-image" :img-path="imgPath" :img-desc="`${cardName} 이미지`">
      <i class="icon-promotion" v-if="showPromotionIcon(compNm)"></i>
    </ep-image>
    <div class="ep-benefit-card-information">
      <div class="ep-benefit-card-name">{{ cardName }}</div>
      <div class="ep-benefit-card-description">{{ desc1 }}</div>
    </div>
    <div v-if="supportText" class="ep-benefit-card-benefit">
      <span class="ep-benefit-card-benefit-icon" />
      <span class="ep-benefit-card-benefit-total">{{ supportText }} 지급</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import "intersection-observer";
import EpImage from "@/components/common/EpImage";

export default {
  name: "EpBenefitCard",
  components: {
    EpImage
  },
  props: {
    cardSeq: {
      type: String,
      default: ""
    },
    promotionSeq: {
      type: String,
      default: ""
    },
    itSeq: {
      type: String,
      default: ""
    },
    cardName: {
      type: String,
      default: ""
    },
    desc1: {
      type: String,
      default: ""
    },
    imgPath: {
      type: String,
      default: ""
    },
    supportText: {
      type: String,
      default: ""
    },
    destinationType: {
      type: String,
      default: "D" // C: 지정페이지, D: 상세페이지, P: 프로모션
    },
    destinationUrl: {
      type: String,
      default: ""
    },
    gpMappSeq: {
      type: Number,
      default: null
    },
    adsDtlSeq: {
      type: Number,
      default: null
    },
    adsSeq: {
      type: Number,
      default: null
    },
    payYn: {
      type: String,
      default: "N"
    },
    compNm: {
      type: String,
      default: ""
    },
    isEventOpen: {
      type: Boolean
    },
    eventCompList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      observer: null,
      displayed: false
    };
  },
  methods: {
    onClick(e) {
      this.$emit("click-card", e);
    },
    // TODO 이벤트 종료 후 삭제 필요
    showPromotionIcon(compName) {
      return this.eventCompList.includes(compName) && this.isEventOpen;
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.displayed) {
            this.displayed = true;
            this.$emit("display-card", this.$el);
          }
        });
      },
      {
        root: null,
        threshold: 0
      }
    );
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
.ep-benefit-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: 110px;
  padding: rem(21px) rem(12px);
  border-bottom: 1px solid $color-grey-1;
  box-sizing: content-box;

  &-information {
    flex: 1;
    padding: 0 0 0 15px;
  }

  &-image {
    flex: 0 0 rem(30px);
    position: relative;
  }

  &-name {
    @include ellipsis(1);
    color: #333333;
    @include bold_14_24(-0.3px);
    margin-bottom: 1px;
  }

  &-description {
    @include ellipsis(1);
  }

  &-description,
  &-benefit {
    @include normal_13_21(-0.006em);
    color: $color-grey-4;
  }

  &-benefit {
    margin: 0 0 0 rem(15px);
    padding: rem(14px) rem(10px);
    border-radius: rem(6px);
    background-color: #fffcea;

    &-icon {
      display: inline-block;
      width: 15px;
      height: 11px;
      background: url("~@/assets/icons/icon-money@2x.png") no-repeat 0 0;
      background-size: contain;
      border-radius: 50%;
    }

    &-total {
      color: $color-grey-6;
      @include bold_14_21(-0.2px);
      margin-left: 5px;
    }
  }

  .icon-promotion {
    background-image: url("~@/assets/icons/icon-promotion@2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    bottom: -1px;
    left: -5px;
  }
}
</style>
