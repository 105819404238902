<template>
  <div class="loading-bar-wrap" :class="{ dark: isDark }">
    <div class="loading-bar">
      <div style="width: 104px; height: 87px" ref="lottieContainer"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-bar {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
}
</style>

<script>
import lottie from "lottie-web";

const animationData = require("@/assets/lottie/loading-sk.json");

export default {
  name: "LoadingBar",
  props: {
    isDark: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData
    });
  }
};
</script>
