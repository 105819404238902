<template>
  <div>
    <!--    <page-tab /> 사업팀 요청에 따라 제거-->
    <div
      class="page-container"
      :class="{
        'page-unloaded': !isPageLoaded
      }"
    >
      <slot />
    </div>
    <loading-bar v-if="!isPageLoaded" />
  </div>
</template>

<script>
import LoadingBar from "@/components/LoadingBar.vue";

export default {
  name: "PageContainer",
  components: {
    LoadingBar
  },
  props: {
    isPageLoaded: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  //padding-bottom: 8px;
  background-color: #f8f8f8;
  transition: opacity 0.2s ease-in;
  opacity: 1;

  &.page-unloaded {
    opacity: 0;
  }

  /*&.has-fixed-tabs {
    padding-top: 34px;
  }
  */
  &.has-fixed-header {
    padding-top: 46px;
  }
}
</style>
